var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.loaded
    ? _c(
        "div",
        { staticClass: "start-a-business" },
        [
          _c("progress-steps", {
            attrs: {
              subtitle: _vm.subtitle,
              "progress-status": _vm.progressStatus,
            },
          }),
          _c("div", { staticClass: "main-container" }, [
            _c("h2", { staticClass: "sl__h2 container-header" }, [
              _vm._v(
                "\n      What‘s your company name in " +
                  _vm._s(_vm.jurisdiction.state_province_region) +
                  "?\n    "
              ),
            ]),
            _c("div", { staticClass: "container-body" }, [
              _c("div", { staticClass: "show-full-name" }, [
                _c("h4", { staticClass: "sl__h4 show-full-name-text" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.newFullCompanyName) +
                      "\n        "
                  ),
                ]),
              ]),
              _c("div", { staticClass: "form-container" }, [
                _c(
                  "div",
                  {
                    staticClass: "company-name",
                    class: _vm.showEntityTypeSuffixes
                      ? "half-width"
                      : "full-width",
                  },
                  [
                    _c("b-form-input", {
                      attrs: { placeholder: "Company Name" },
                      model: {
                        value: _vm.newCompanyName,
                        callback: function ($$v) {
                          _vm.newCompanyName = $$v
                        },
                        expression: "newCompanyName",
                      },
                    }),
                  ],
                  1
                ),
                _vm.showEntityTypeSuffixes
                  ? _c(
                      "div",
                      { staticClass: "half-width" },
                      [
                        _c("b-form-select", {
                          staticClass: "pre-stageline-dropdown",
                          attrs: { options: _vm.entityTypeSuffixes },
                          model: {
                            value: _vm.selectedEntitySuffix,
                            callback: function ($$v) {
                              _vm.selectedEntitySuffix = $$v
                            },
                            expression: "selectedEntitySuffix",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
            ]),
          ]),
          _c("button-container", {
            attrs: {
              "next-button-label": _vm.nextButtonLabel,
              "show-previous-button": _vm.showPreviousButton,
              "disable-next-button": !_vm.valid,
            },
            on: { next: _vm.checkForSimilarName },
          }),
        ],
        1
      )
    : _c(
        "div",
        { staticClass: "center-spinner" },
        [_c("ct-centered-spinner")],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }