<template>
  <div v-if="loaded" class="start-a-business">
    <progress-steps
      :subtitle="subtitle"
      :progress-status="progressStatus"
    />

    <div class="main-container">
      <h2 class="sl__h2 container-header">
        What&lsquo;s your company name in {{ jurisdiction.state_province_region }}?
      </h2>
      <div class="container-body">
        <div class="show-full-name">
          <h4 class="sl__h4 show-full-name-text">
            {{ newFullCompanyName }}
          </h4>
        </div>

        <div class="form-container">
          <div
            class="company-name"
            :class="showEntityTypeSuffixes ? 'half-width' : 'full-width'"
          >
            <b-form-input v-model="newCompanyName" placeholder="Company Name" />
          </div>

          <div
            v-if="showEntityTypeSuffixes"
            class="half-width"
          >
            <b-form-select
              v-model="selectedEntitySuffix"
              class="pre-stageline-dropdown"
              :options="entityTypeSuffixes"
            />
          </div>
        </div>
      </div>
    </div>

    <button-container
      :next-button-label="nextButtonLabel"
      :show-previous-button="showPreviousButton"
      :disable-next-button="!valid"
      @next="checkForSimilarName"
    />
  </div>

  <div v-else class="center-spinner">
    <ct-centered-spinner />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { isNotEmptyOrNull } from '@/common/modules/strings'

export default {
  name: 'CompanyName',
  components: {
    CtCenteredSpinner: () => import('@/components/shared/CtCenteredSpinner'),
    ProgressSteps: () => import('@/components/StagelineV2/domainsFirst/shared/ProgressSteps'),
    ButtonContainer: () => import('@/components/StagelineV2/domainsFirst/shared/ButtonContainer'),
  },
  data () {
    return {
      subtitle: 'company name',
      progressStatus: 60,
      nextButtonLabel: 'Get Started',
      showPreviousButton: true,
      company: null,
      selectedEntitySuffix: null,
      newCompanyName: null,
    }
  },
  computed: {
    ...mapGetters('stagelineCreateCompany', [
      'jurisdiction',
      'entityType',
      'entityTypeSuffix',
      'companyName',
      'loaded',
    ]),
    ...mapGetters('entityTypes', ['entityTypeSuffixes']),

    showEntityTypeSuffixes() {
      return this.entityTypeSuffixes ? this.entityTypeSuffixes.length > 0 && this.loaded : false
    },
    newFullCompanyName() {
      return this.selectedEntitySuffix ? [this.newCompanyName, this.selectedEntitySuffix].join(' ')
        : this.newCompanyName
    },
    valid() {
      const companyNameIsValid = isNotEmptyOrNull(this.newCompanyName)
      const entityTypeIdIsValid = isNotEmptyOrNull(this.entityType.id)
      const companyHomeState = isNotEmptyOrNull(this.jurisdiction.id)
      const entityTypeSuffixIsValid = this.showEntityTypeSuffixes ? isNotEmptyOrNull(this.selectedEntitySuffix) : true
      return companyNameIsValid && entityTypeIdIsValid && companyHomeState && entityTypeSuffixIsValid
    },
  },
  async mounted() {
    this.setLoaded(false)
    await this.loadEntityTypeSuffixes()
    this.setSelectedCompanyNameAndSuffix()
    this.showLoading()
  },
  methods: {
    ...mapActions('entityTypes', [
      'getEntityTypeSuffixes',
    ]),
    ...mapActions('stagelineCreateCompany', [
      'setCompanyNameAndSuffix',
      'setCurrentSlide',
      'setLoaded',
      'ensureCompanyNameUniqueness',
      'createCompany',
      'setCreatingCompany',
    ]),
    ...mapActions('stageline', [
      'getAccountCompaniesSimple',
    ]),
    async loadEntityTypeSuffixes() {
      await this.getEntityTypeSuffixes({
        entityTypeId: this.entityType.id,
        jurisdictionId: this.jurisdiction.id,
      })
    },
    async checkForSimilarName() {
      this.setLoaded(false)

      try {
        await this.setCompanyNameAndSuffix({
          companyName: this.newCompanyName,
          entityTypeSuffix: this.selectedEntitySuffix,
        })

        const result = await this.ensureCompanyNameUniqueness()
        await this.handleNameUniquenessResult(result)
      } catch(_error) {
        this.$bvToast.toast("An unexpected error occurred. Please try again.", {
          title: 'Error',
          variant: 'danger',
          solid: true,
        })
      } finally {
        this.setLoaded(true)
      }
    },
    async handleNameUniquenessResult(result) {
      if (result?.data) {
        const success = result.data.result?.success
        const slideName = success ? 'ConfirmCompany' : 'SimilarCompanyName'
        this.setCurrentSlide(slideName)
      } else {
        this.$bvToast.toast("An error has occurred while checking the company name's uniqueness", {
          title: 'Error',
          variant: 'danger',
          solid: true,
        })
      }
    },
    setSelectedCompanyNameAndSuffix() {
      const validEntitySuffix = this.entityTypeSuffixes.some(s => s.text === this.entityTypeSuffix)
      this.selectedEntitySuffix = validEntitySuffix ? this.entityTypeSuffix : null
      this.newCompanyName = this.companyName
    },
    showLoading() {
      setTimeout(() => { this.setLoaded(true) }, 500)
    },
  },
}
</script>

<style lang="scss" scoped>
.sl__h2 {
  text-align: left;
}

.sl__h4 {
  text-align: center;
}

.start-a-business {
  width: 100%;
  margin: 0;
  padding: 6% 17% 0 17%;

  .main-container {
    margin-bottom: 8.0em;
    @include sl__flex(column, center, flex-start);

    .container-header {
      margin-bottom: 1.5em;
    }

    .container-body {
      width: 100%;
      padding: 0 2.0em;

      .show-full-name {
        width: 100%;
        margin-bottom: 1.5em;
        @include sl__flex(row, center, center);

        .show-full-name-text {
          color:$sl__violet2;
        }
      }

      .form-container {
        width: 100%;
        @include sl__flex(row, space-between, center);

        .company-name {
          margin-right: 1.0em;
          @include sl__flex(row, center, center);
        }

        .full-width {
          width: 100%;
        }

        .half-width {
          width: 50%;
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .start-a-business {
    padding-left: 0;
    padding-right: 0;

    .main-container {
      margin-bottom: 4.0em;

      .container-body {

        .form-container {
          flex-direction: column;
          justify-content: center;

          .company-name {
            margin-right: 0;
            margin-bottom: 1.0em;
          }

          .half-width {
            width: 100%;
          }
        }
      }
    }
  }
}
</style>
